import React, { useState,useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import RealEstateImage from '../assets/images/Mask Group.png';
import RealEstateLocation from '../assets/images/Location.png';
import EmailIcon from '../assets/images/Email.png';
import ContactIcon from '../assets/images/Group 1686553877.png';
import './RealEstateView.css';
import { CSmartTable } from '@coreui/react-pro';
import ProjectServices from 'services/ProjectServices';
import { useLocation, useNavigate } from 'react-router';
import arrowleft from '../assets/images/arrowleft.png'
import axios from 'axios';
import { CTooltip } from '@coreui/react-pro';


const InspectorView = () => {
  const location=useLocation();
  console.log("owner State",location?.state);
  const ownerState=location?.state;
  const navigate=useNavigate();
  const [buttonclass,setbuttonclass]=useState(1);
  const [loading,setloading]=useState(false);

  const [activePage, setActivePage] = useState(1);
  const [columnFilter, setColumnFilter] = useState({});
  const [columnSorter, setColumnSorter] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [cashBack, setcashBack] = useState([]);
  const [pointsEarned,setpointsEarned]=useState(null);
  const [cashbackEarned,setcashbackEarned]=useState(null);
  const [records, setRecords] = useState(0); // Initialize records with 0
  const [gift,setgift]=useState('');
  const [detailsData,setdetailsData]=useState(null);

  const handleColor=(item)=>{
    console.log("item.status",item.status === 'inprogress');
    if(item.status === 'inprogress' === true) return 'blue';
    if(item.status === 'active' === true) return 'yellow';
    if(item.status === 'completed' === true) return 'green';
    if(item.status === 'completed_review' === true) return 'red';
    if(item.status === 'completed_review' === true) return 'red';
    if(item.status === 'completed_review' === true) return 'red';
    else return 'orange'
  }


  const ViewEventHandler = (item) => {
    console.log(item,"vf,mvkfvkf");
    navigate(`/view/repair/${item._id}`, { state: item.records  });
  };

  const EditProjectHandler = (item) => {
    navigate(`/repair/${item._id}`, { state: item.records  });
  };

  useEffect(()=>{
    if(ownerState?.id){
    const getUserDetails=async()=>{
      const token=localStorage.getItem('token');
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/v1/get/details?userId=${ownerState?.id}`,{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      })
      .then((res)=>{
        console.log("res details homeowner",res);
        setdetailsData(res?.data?.records[0])
    })
      .catch((err)=>{console.log("err detail homeowner",err)})
    }
    getUserDetails();
  }
  },[ownerState])

  useEffect(()=>{
    console.log("detailsData",detailsData);
  },[detailsData])

  useEffect(() => {
    setloading(true);
    const offset = itemsPerPage * (activePage - 1);
    let params = new URLSearchParams();
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key]);
    });
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`);
      const token=localStorage.getItem("token");
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/v1/get/point-list?userId=${ownerState?.id}`,{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      })
      .then((res)=>{
        console.log("homeowner view",res);
        setpointsEarned(res?.data?.rewards[0]?.totalAvailableAmount-res?.data?.rewards[0]?.withdrawalPoints)
        const updatedCustomers = getCustomers(res?.data?.rewards[0]?.data, offset);
        console.log("updated customers homeowner",updatedCustomers);
        setUsers(updatedCustomers);
        setloading(false);
    })
      .catch((err)=>{console.log("homeowner error view",err)})
        // setRecords(resp.data.length);
        
  }, [activePage, itemsPerPage]);


  useEffect(() => {
    setloading(true);
    const offset = itemsPerPage * (activePage - 1);
    let params = new URLSearchParams();
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key]);
    });
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`);
      const token=localStorage.getItem("token");
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/v1/get/cashback-list?userId=${ownerState?.id}`,{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      })
      .then((res)=>{
        console.log("homeowner view cashback",res);
        setcashbackEarned(res?.data?.rewards[0]?.totalAvailableAmount-res?.data?.rewards[0]?.withdrawalPoints);
        const updatedCustomers = getCashBack(res?.data?.rewards[0]?.data, offset);
        console.log("updated customers homeowner cashback",updatedCustomers);
        setcashBack(updatedCustomers);
        setloading(false);
    })
      .catch((err)=>{console.log("homeowner error view",err)})
        // setRecords(resp.data.length);
        
  }, [activePage, itemsPerPage]);

  const getCustomers = (data, offset) => {
    return (data || []).slice(offset, offset + itemsPerPage).map((record, index) => {
      // Extracting service names from the services array
      console.log("Jai ho home view",record )
      return {
        User_Id: index + offset + 1,
        type: record?.entity,
        amount: record?.points,
        start_date:convertDate(record?.createdAt),
        end_date:convertDate(record?.available_on),
        newtype:record?.type,
        services:record?.entityDetails?.serviceDetails

      };
    });
  };
  const getCashBack = (data, offset) => {
    return (data || []).slice(offset, offset + itemsPerPage).map((record, index) => {
      // Extracting service names from the services array
      console.log("Jai ho home view",record )
      return {
        User_Id: index + offset + 1,
        type: record?.entity,
        amount: record?.amount ? record?.amount: "null",
        start_date:convertDate(record?.createdAt),
        end_date:convertDate(record?.available_on),
        newtype:record?.type,
        services:record?.entityDetails?.serviceDetails

      };
    });
  };

  const convertDate=(originalDateString)=>{
    const originalDate = new Date(originalDateString);

    // Format the date into "Mar 30, 2024" format
    const formattedDate = originalDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    });
    return formattedDate;
  }

  const checkString=(mystring)=>{
    if(mystring === undefined) return "";
    return mystring;
  }

  const columns = [
    {
      key: 'User_Id',
      label: 'Id',
      _style: { minWidth: '100px', background: "#FFEFE2", color: "black", padding:20 },
      filter: true, // Enable filtering
    },
    {
      key: 'type',
      label: 'Type',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
    ////cdc
    {
      key: 'amount',
      label: 'Points',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
    {
      key: 'start_date',
      label: 'Start Date',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
    {
      key: 'end_date',
      label: 'End Date',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
  ];
  const othercolumns = [
    {
      key: 'User_Id',
      label: 'Id',
      _style: { minWidth: '100px', background: "#FFEFE2", color: "black", padding:20 },
      filter: true, // Enable filtering
    },
    {
      key: 'type',
      label: 'Type',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
    ////cdc
    {
      key: 'amount',
      label: 'Points',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
    {
      key: 'start_date',
      label: 'Start Date',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
    {
      key: 'end_date',
      label: 'End Date',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" , padding:20 },
      filter: true, // Enable filtering
    },
  ];

  return (
    <>{loading && ( // Conditionally render loader
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  )}
    <MainCard>
        <div>
        <img src={arrowleft} onClick={()=>{navigate('/users/home_owner')}} alt='arrow_left' height={25} width={25}/>
           <div className='first_div'>
            <div className='image_details'>
            <img src={detailsData?.userBasicInfo?.profile_image?detailsData?.userBasicInfo?.profile_image:RealEstateImage} width={150} height={140}/>
            <div>
              <h1>{checkString(detailsData?.userInfo?.first_name) +" "+ checkString(detailsData?.userInfo?.last_name)}</h1>
              <p><img src={RealEstateLocation} height={32} width={32}/>{checkString(detailsData?.userBasicInfo?.location?.location_name)}</p>
              <div className='email_contact'>
                <p><img src={EmailIcon} height={25} width={25}/>{checkString(detailsData?.userInfo?.email_address)}</p>
                <p><img src={ContactIcon} height={25} width={25}/>+{checkString(detailsData?.userInfo?.mobile_number?.country_code) + " " + checkString(detailsData?.userInfo?.mobile_number?.phone_number)}</p>
              </div>
            </div>
            </div>
            <div>
              <p className='earned'>Cashback earned:<span> {cashbackEarned<0 || isNaN(cashbackEarned)?0:cashbackEarned}pts</span></p>
              <p className='earned'>Points Earned:<span> {pointsEarned<0  || isNaN(pointsEarned)?0:pointsEarned}pts</span></p>
            </div>
           </div>
           <hr/>
           <div className='cashback_users'>
            <button onClick={()=>{setbuttonclass(1)}} className={buttonclass === 1?'button-active':''}>Cashback</button>
            <button onClick={()=>{setbuttonclass(2)}} className={buttonclass === 2?'button-active':''}>Redeem Points</button>
            {/* <button onClick={()=>{setbuttonclass(3)}} className={buttonclass === 3?'button-active':''}>Projects</button> */}
           </div>
           <CSmartTable
        columns={buttonclass === 1?columns:othercolumns}
        columnFilter
        items={buttonclass === 1?cashBack:users}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        itemsPerPageOptions={[5, 10, 20]} // Define options for items per page
        pagination
        clickableRows
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(records / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onFilteredItemsChange={(items) => {
          console.log("myItems",items)
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1);
          setColumnFilter(filter);
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1);
          setItemsPerPage(itemsPerPage);
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
        scopedColumns={{
          amount:(item)=>(
            <td>
              <span style={{color:`${item?.newtype === "credit"?"green":"red"}`}}>{item?.newtype === "credit"?"+"+item?.amount:"-"+item?.amount}</span>
            </td>
          ),
          type:(item)=>(
            <td>
              {item?.type === "project"?
              <CTooltip content={item?.services?.map((item,index)=>{return<p>{item?.name}</p>})} placement='right'>
                <p>{item?.services[0]?.name + "..."}</p>
              </CTooltip>
              :<p>Withdrawal</p>}
            </td>
          )
        }}
      />
           </div>
    </MainCard>
    </>
  )
}

export default InspectorView