import requests from "./httpService";
// import axios from "axios";

const moveTologin=(err)=>{
    if(err.status === 401){
    window.location='/login'}
  }

const ProjectServices = {
    getAllInspection:async(status)=>{
        return requests.get(`admin/v1/inspection/get?status=${status}`);
    },
      
    getAllProject: async (type,page) => {
        console.log("page api check",page);
        // console.log(params)
        return requests.get(`admin/v1/projects/get?project_type=${type}`);
    },
    Patch:async({id,is_active})=>{
          console.log(id,is_active,"give code")
        return requests.patch(`admin/v1/services/update`)
    },

    GetOne: async ({serviceId}) => {
        return requests.get(`admin/v1/services/getone?serviceId=${serviceId}`);
    },
};

export default ProjectServices;
