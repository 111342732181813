import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import InspectionCompleted from 'views/utilities/InspectionCompleted';
import HomeOwnerView from 'views/HomeOwnerView';
import ContractorView from 'views/ContractorView';
import InspectorView from 'views/InspectorView';
import RequestPointsTable from 'component/DataTable/RequestPointsTable';
import WithdrawalPointsTable from 'component/DataTable/WithdrawalPointsTable';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')))
const RealStateAgent =Loadable(lazy(() => import('views/utilities/TablerIconsAgents')))
const Inspector  =Loadable(lazy(() => import('views/utilities/TablerIconsInspectpor')))
const HomeOwner  =Loadable(lazy(() => import('views/utilities/TablerIconsHomeOwner')))
const Contractor  =Loadable(lazy(() => import('views/utilities/Contractor')))
const Privacy =Loadable(lazy(() => import('views/utilities/Privacy')))
const About_us =Loadable(lazy(() => import('views/utilities/About_us')))
const HelpAndSupport =Loadable(lazy(() => import('views/utilities/HelpAndSupport')))
const TermsAndCondtions =Loadable(lazy(() => import('views/utilities/TermsAndCondtions')))
const Category = Loadable(lazy(() => import('views/utilities/Services')));
const EditProject =Loadable(lazy(() => import('views/utilities/EditProject')));
const RepairViewProject =Loadable(lazy(() => import('views/utilities/RepairViewProject')));
const EditRepair =Loadable(lazy(() => import('views/utilities/ContractorDataTable/EditRepair')));
const RealEstateView=Loadable(lazy(()=>import('views/RealEstateView')))
const RealEstateUserEdit=Loadable(lazy(()=>import('views/RealEstateUserEdit')))

// const Contractor =Loadable(()=>import('views/Contractor/Contractor'))

// const Contractor =Loadable(()=>import('views/Contractor/Contractor'))
// import Contractor from 'views/Contractor/Contractor';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
 
        path: '/repair/:id',
        element: <EditRepair />
    },
    {
 
      path: '/view/repair/:id',
      element: <RepairViewProject />
  },
  {
 
    path: '/renovation/:id',
    element: <EditProject />
},
{

  path: '/view/renovation/:id',
  element: <RepairViewProject />
},
{

  path: '/inspection/completed/:id',
  element: <InspectionCompleted />
},
{
  path:'/users/real_estate/view',
  element:<RealEstateView/>
},
{
  path:'/users/real_estate/view/user/edit',
  element:<RealEstateUserEdit/>
},
{
  path:'/users/contractor/view',
  element:<ContractorView/>
},
{
  path:'/users/inspector/view',
  element:<InspectorView/>
},
{
  path:'/users/home_owner/view',
  element:<HomeOwnerView/>
},
{
  path:'/request/points',
  element:<RequestPointsTable/>
},
{
  path:'/request/withdrawal',
  element:<WithdrawalPointsTable/>
},
    {
      path: 'dashboard',
      element: <DashboardDefault />

    },
    {
      path: 'repair',
      element: <UtilsTypography type = 'repair' />

    },
    {
      path: 'renovation',
      element: <UtilsTypography type = 'renovation' />

    },
    {
      path: 'inspection',
      element: <UtilsTypography type = 'inspection' />
    },
    {
      path: 'services',
      element: <Category />
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },

    {
      path: 'config',
      children: [
        {
          path: 'about_us',
          element: <About_us />
        }
      ]
    },
    {
      path: 'config',
      children: [
        {
          path: 'about_us',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'config',
      children: [
        {
          path: 'about_us',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'config',
      children: [
        {
          path: 'privacy_policy',
          element: <Privacy />
        }
      ]
    },
    {
      path: 'config',
      children: [
        {
          path: 'help_center',
          element: <HelpAndSupport />
        }
      ]
    },

    {
      path: 'config',
      children: [
        {
          path: 'terms_and_conditions',
          element: <TermsAndCondtions />
        }
      ]
    },

    {
      path: 'users',
      children: [
        {
          path: 'home_owner',
          element: <HomeOwner />
        }
      ]
    },

    {
      path: 'users',
      children: [
        {
          path: 'contractor',
          element: <Contractor />
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'inspector',
          element: <Inspector />
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'real_estate',
          element: <RealStateAgent />
        }
      ]
    },
    
    {
        path: 'sample-page',
        element: <SamplePage />

    }
  ]
};

export default MainRoutes;
