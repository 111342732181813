import { Typography } from '@mui/material';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router';
import pdfImage from '../../assets/images/pdf 1.png';
import './InspectorCompletedView.css'

const InspectorCompletionModel = ({compModel,setcompModel,compItem}) => {
    console.log("called");
    console.log("compItem",compItem);
    const [reportShow,setreportShow]=useState(false);
    const downloadPdf = () => {
        // Replace 'pdfUrl' with the actual URL of the PDF file
        const pdfUrl = compItem?.records?.report?.report_file;
        
        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'downloaded_pdf.pdf'; // Specify the filename for download
        anchor.target="_blank";
        anchor.click();
    };
    const handleColor=(item)=>{
      console.log("item.status",item === 'inprogress');
      if(item === 'inprogress' === true) return 'blue';
      if(item === 'active' === true) return 'yellow';
      if(item === 'completed' === true) return 'green';
      if(item === 'completed_review' === true) return 'red';
      if(item === 'rejected' === true) return 'red';
      if(item === 'completed_review' === true) return 'red';
      else return 'orange'
    }
    // sourabh
  return (
    
    <Modal show={compModel} className='comp-modal' centered onHide={()=>{setcompModel(false);setreportShow(false)}}>
    <Modal.Header closeButton>
        <Modal.Title style={{ flexGrow: 1, textAlign: 'center' }}>View Your Report</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight:'400px' ,overflowY: 'auto',padding:30 }}>
      <button className='report_button' onClick={()=>{setreportShow(!reportShow)}} >{reportShow?'Hide Report':'View Report'}</button>
        {reportShow &&
        <div>
        <Typography className='upload_pdf'>Uploaded Pdf</Typography>
        <div className='pdf_text'>
        <img src={pdfImage} alt='pdf-image' height={55} width={55} onClick={()=>downloadPdf()}/>
        <Typography>xyz.pdf</Typography>
        </div>
        <Typography className='upload_pdf'>Comments</Typography>
        <div className='pdf_text'>
        <Typography>{compItem?.records?.report?.report_comment}</Typography>
        </div>
        <Typography className='upload_pdf'>Suggested Service Category</Typography>
        <div className='pdf_text'>
            {
                compItem?.records?.report?.report_services?.map((item,index)=>{
                    return <Typography>{item?.name}</Typography>
                })
            }
        </div>
        <hr/>
        </div>
        }
      <div className='report_modal'>
          <div>
        <label>Inspection Id</label>
        <Typography>{compItem?.id}</Typography>
          </div>
          <hr/>
          <div>
        <label>Username</label>
        <Typography>{compItem?.customerName}</Typography>
          </div>
          <hr/>
          <div>
        <label>Inspector Name</label>
        <Typography>{compItem?.inspectorName}</Typography>
          </div>
          <hr/>
          <div>
        <label>Location</label>
        <Typography>{compItem?.location}</Typography>
          </div>
          <hr/>
          <div>
        <label>Status</label>
        <Typography className='status_cap' style={{color:handleColor(compItem?.status)}}>{compItem?.status}</Typography>
          </div>
          <hr/>
        </div>
        
      </Modal.Body>
    </Modal>
  )
}

export default InspectorCompletionModel
