import InspectorCompletionModel from 'component/Modals/InspectorCompletionModel';
import React from 'react'
import { useLocation } from 'react-router';

const InspectionCompleted = () => {
    const location=useLocation();
    const CompletionState=location.state;
    console.log("completion",CompletionState);
  return (
    <InspectorCompletionModel/>
  )
}

export default InspectionCompleted