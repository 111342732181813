import React, { useState, useEffect } from 'react';
import { CSmartTable } from '@coreui/react-pro';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom'; // Changed import for useNavigate
import ProjectServices from 'services/ProjectServices';
import './style.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CTooltip } from '@coreui/react-pro';
import { CircularProgress } from '@mui/material';
import { ToastContainer,toast } from 'react-toastify';
import cancelImage from '../../assets/images/Group 1686554699.png';
import correctImage from '../../assets/images/Group 1686554700.png';
import thirdImage from '../../assets/images/Group 1686554701.png';
import RequestPointsConfirmModal from 'component/Modals/RequestPointsConfirmModal';
import CheckPointsModal from 'component/Modals/CheckPointsModal';
import ConfettiExplosion from 'react-confetti-explosion';
import axios from 'axios';
import WithdrawalPointsModal from 'component/Modals/WithdrawalPointsModal';
import PointsRejectedModal from 'component/Modals/PointsRejectedModal';


export function WithdrawalPointsTable() {
  const navigate = useNavigate(); // Corrected the useNavigate usage
  const [activePage, setActivePage] = useState(1);
  const [columnFilter, setColumnFilter] = useState({});
  const [columnSorter, setColumnSorter] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [records, setRecords] = useState(0);
  const [loading, setLoading] = useState(false); // New state for loading indicator
  // Initialize records with 0
  const [myprogValues,setprogValues]=useState([]);
  const [budgetValues,setbudgetValues]=useState([])
  const [myPage,setmyPage]=useState(0);
  const [confirmModal,setconfirmModal]=useState(false);
  const [checkModal,setcheckModal]=useState(false);
  const [explosion,setexplosion]=useState(false);
  const [confirmModalData,setconfirmModalData]=useState(null);
  const [refreshData,setrefreshData]=useState(false);
  const [rejModal,setrejModal]=useState(false);

  useEffect(()=>{
    setprogValues([]);
  },[])

  useEffect(() => {
    setLoading(true);
    const offset = itemsPerPage * (activePage - 1);
    let params = new URLSearchParams();
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key]);
    });
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`);
      const token=localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/v1/reward/get?type=cashback&page=${activePage}`,{
        headers:{
            "Authorization":`Bearer ${token}`
        }
    })
      .then((response) => {
        console.log("response for cashback",response);
        setRecords(response?.data?.totalCount);
        const updatedCustomers = getCustomers(response?.data?.data);
        console.log("updated customers repair",updatedCustomers);
        // console.log("BudgetObj",BudgetObj);
        // setbudgetValues(BudgetObj);
        setLoading(false);
        setUsers(updatedCustomers);
      })
      .catch((err)=>{if(err?.response?.data?.status == 401){navigate('/login')}})
  }, [activePage,itemsPerPage,refreshData]);

  useEffect(()=>{
    console.log("myprogValues",budgetValues);
  },[budgetValues])

  const convertDate=(originalDateString)=>{
    const originalDate = new Date(originalDateString);

    // Format the date into "Mar 30, 2024" format
    const formattedDate = originalDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    });
    return formattedDate;
  }

  const getCustomers = (data, offset) => {
    return data.map((record, index) => {
      // Extracting service names from the services array
      console.log("Jai ho token withdraw",record )
      // const serviceNames = record.services.map(service => service.name).join(', ');
      // console.log("services for sourabh",serviceNames);
      // const firstServiceName = record.services.length > 0 ? record.services[0].name : '';
      // const serviceNames = record.services.length > 1 ? `${firstServiceName}...` : firstServiceName;
      console.log("record services",record?.services);
      return {
        uniqueId:record?.uniqueId,
        name:record?.userId?.userInfo?.first_name + "  " + record?.userId?.userInfo?.last_name,
        amount:record?.amount,
        date:convertDate(record?.createdAt),
        _id:record?._id,
        status:record?.status,
        description:record?.description
      };
    });
  };
  // const handleColor=(item)=>{
  //   console.log("item.status",item.status === 'inprogress');
  //   if(item.status === 'inprogress' === true) return 'blue';
  //   if(item.status === 'active' === true) return 'yellow';
  //   if(item.status === 'completed' === true) return 'green';
  //   if(item.status === 'rejected' === true) return 'red';
  //   if(item.status === 'completed_review' === true) return 'purple';
  //   if(item.status === 'completed_review' === true) return 'pink';
  //   else return 'orange'
  // }
  
  

  const columns = [
    {
      key: 'uniqueId',
      label: 'ID',
      _style: { minWidth: '70px', background: "#FFEFE2", color: "black" },
    },
    {
      key: 'name',
      label: 'Name',
      _style: { width: '130px', background: "#FFEFE2", color: "black" },// Enable sorting
    },
    ////cdc
    {
      key: 'date',
      label: 'Date',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" },
    },
    {
      key: 'amount',
      label: 'Points',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" },
    },
    {
      key: 'status',
      label: 'Status',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" },
    },
    {
      key: 'action',
      label: 'Action',
      _style: { minWidth: '130px', background: "#FFEFE2", color: "black" },
      filter: false,
      sorter: false,
    },
  ];

  // const ViewEventHandler = (item) => {
  //   console.log(item,"vf,mvkfvkf");
  //   navigate(`/view/repair/${item._id}`, { state: item.records  });
  // };

  // const EditProjectHandler = (item) => {
  //   navigate(`/repair/${item._id}`, { state: item.records  });
  //   console.log("called");
  // };
  

  return (
  
    <div>
        {explosion && <div style={{marginLeft:'550px'}}><ConfettiExplosion onComplete={()=>{setexplosion(false)}}/></div>}
      {loading && ( // Conditionally render loader
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <CSmartTable
        columns={columns}
        columnFilter
        columnSorter
        items={users}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        itemsPerPageOptions={[5, 10, 20]} // Define options for items per page
        pagination={{
          external:true
        }}
        tableFilter
        clickableRows
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(records / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onFilteredItemsChange={(items) => {
          items?.map((item,index)=>{
            console.log('item?.record?.acceptedBids',index,"---",item?.records?.acceptedBids?.length);
            if(item?.records?.acceptedBids?.length === 0){
              setprogValues(progValues=>[...progValues,10])
            }
            else {item?.records?.acceptedBids?.map((item,index)=>{
              setprogValues(progValues=>[...progValues,Number(item?.payment_percentage)])
              console.log("progValues Again",myprogValues);
            })}
          })
        }}
        onActivePageChange={(activePage) => {setActivePage(activePage);setmyPage(myPage+1)}}
        onColumnFilterChange={(filter) => {
          setActivePage(1);
          setColumnFilter(filter);
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1);
          setItemsPerPage(itemsPerPage);
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
        scopedColumns={{
          action: (item) => (
            <td>
              
              <div style={{ display: "flex", columnGap: "10px" }}>
                {item?.status === "pending" && <img onClick={()=>{setrejModal(true);setconfirmModalData(item)}}src={cancelImage} height={35} width={35}/>}
                {item?.status === "pending" && <img onClick={()=>{setconfirmModalData(item);setconfirmModal(true)}} src={correctImage} height={35} width={35}/>}
                {item?.status !== "pending" &&<span className='view_span' onClick={()=>{setconfirmModalData(item);setcheckModal(true)}}>View</span>}
              </div>
            </td>
          ),
          status:(item)=>(
            <td>
              <span className={`${item?.status === 'rejected'?'span_rej':'span_accept'}`}>{item?.status?.toUpperCase()}</span>
            </td>
          )
        }}
      />
      <div> {records} entries</div>
      <WithdrawalPointsModal setrefreshData={setrefreshData} refreshData={refreshData} confirmModal={confirmModal} confirmModalData={confirmModalData} setconfirmModal={setconfirmModal} setexplosion={setexplosion}/>
      <CheckPointsModal confirmModalData={confirmModalData} checkModal={checkModal} setcheckModal={setcheckModal}/>
      <PointsRejectedModal setrefreshData={setrefreshData} refreshData={refreshData} setrejModal={setrejModal} rejModal={rejModal} confirmModalData={confirmModalData}/>
    </div>
  );
}

export default WithdrawalPointsTable;
