import { Typography } from '@mui/material';
import React from 'react';
import { Modal } from 'react-bootstrap';
import './ReqPointConf.css'

const CheckPointsModal = ({checkModal,setcheckModal,confirmModalData}) => {
  console.log("view data",confirmModalData);
  return (
    <Modal className='point_conf_modal'  show={checkModal} onHide={()=>{setcheckModal(false)}}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <Typography className='points_redeem_text'>Redeem Points</Typography>
      <div class="container">
            <input value={confirmModalData?.amount} type="text" id="points" name="points" disabled/>
        </div>
        <Typography className='points_redeem_text'>Request Id</Typography>
        <div class="container">
            <input value={confirmModalData?._id} type="text" id="points" name="points" disabled/>
        </div>
        <Typography className='points_redeem_text'>Description</Typography>
        <div class="textarea-check_modal_container">
        <textarea value={confirmModalData?.description} id="points" name="points" rows="5" cols="20"></textarea>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CheckPointsModal