// import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons-react';
import { FaUsers } from "react-icons/fa";
// import { IoIosGitPullRequest } from "react-icons/io";
import { GrConfigure } from "react-icons/gr";
import { GiAutoRepair } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { FaFileCircleCheck } from "react-icons/fa6";
import { MdHelpCenter } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
// import { TbHomeSearch } from "react-icons/tb";
// import { GrUserWorker } from "react-icons/gr";
import { HiHome } from "react-icons/hi";
import { FaUserTie } from "react-icons/fa6";
import { FaUserGear } from "react-icons/fa6";
import RequestIcon from '../assets/images/Mask Group.png';
import { FaCodePullRequest } from "react-icons/fa6";



// constan
// const icons = {
//   IconTypography,
//   IconPalette,
//   IconShadow,
//   IconWindmill
// };

// ==============================|| UTILITIES MENU ITEMS ||===========================//

const utilities = {
  id: 'utilities',
  // title: 'Utilities',
  type: 'group',
  children: [
     {
       id: 'Repair',
       title: 'Repair',
       type: 'item',
       url: '/repair',
       icon: GiAutoRepair,
       breadcrumbs: false,
       
    },
    {
      id: 'Renovation',
      title: 'Renovation',
      type: 'item',
      url: '/renovation',
      icon: GiAutoRepair,
      breadcrumbs: false,
      
      
   },
   {
    id: 'Inspection',
    title: 'Inspection',
    type: 'item',
    url: '/inspection',
    icon: GiAutoRepair,
    breadcrumbs: false,
    
    
 },
    {
      id: 'Services',
      title: 'Services',
      type: 'item',
      url: '/services',
      icon: MdOutlineMiscellaneousServices,
      breadcrumbs: false
   },
   
    // {
    //   id: 'Renovation',
    //   title: 'Renovation',
    //   type: 'item',
    //   url: '/utils/util-color',
    //   icon: icons.IconPalette,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'Inspection',
    //   title: 'Inspection',
    //   type: 'item',
    //   url: '/utils/util-shadow',
    //   icon: icons.IconPalette,
    //   breadcrumbs: false
    // },
    {
      id: 'user',
      title: 'Users',
      type: 'collapse',
      icon: FaUsers,
      children: [
        {
          id: 'Home Owner',
          title: 'Home Owner',
          type: 'item',
          url: '/users/home_owner',
          icon: FaHome,
          breadcrumbs: false
        },
        {
          id: 'Contractor',
          title: 'Contractor',
          type: 'item',
          url: '/users/contractor',
          icon: FaUserGear,
          breadcrumbs: false
        },
        {
          id: 'Real estate Agent',
          title: 'Real estate Agent',
          type: 'item',
          url: '/users/real_estate',
          icon: FaUserTie,
          breadcrumbs: false
        },
        {
          id: 'Inspector',
          title: 'Inspector',
          type: 'item',
          url: '/users/inspector',
          icon: HiHome,  
          breadcrumbs: false
        },
        
      ]
    },

    // {
  
    //     id: 'Repair View Project',
    //     title: 'Repair View Project',
    //     type: 'item',
    //     url: '/config/terms_and_conditions',
    //     icon: FaFileCircleCheck,  
    //     breadcrumbs: fals
    // },
    {
      id: 'config',
      title: 'Configuration',
      type: 'collapse',
      icon: GrConfigure,
      children: [
        {
          id: 'About Us',
          title: 'About Us',
          type: 'item',
          url: '/config/about_us',
          icon: FaHome,
          breadcrumbs: false
        },
        {
          id: 'Privacy Policy',
          title: 'Privacy Policy',
          type: 'item',
          url: '/config/privacy_policy',
          icon: FaLock,
          breadcrumbs: false
        },
        {
          id: 'Support & Help Center',
          title: 'Support & Help Center',
          type: 'item',
          url: '/config/help_center',
          icon: MdHelpCenter,
          breadcrumbs: false
        },
        {
          id: 'Terms and Conditions',
          title: 'Terms and Conditions',
          type: 'item',
          url: '/config/terms_and_conditions',
          icon: FaFileCircleCheck,  
          breadcrumbs: false
        },  
      ]
    },
    // {
    //   id: 'request',
    //   title: 'Request',
    //   type: 'collapse',
    //   icon: GrConfigure,
    //   children: [
    //     {
    //       id: 'Request',
    //       title: 'Request',
    //       type: 'item',
    //       url: '/request/points',
    //       icon: FaHome,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'Withdrawal',
    //       title: 'Withdrawal',
    //       type: 'item',
    //       url: '/request/withdrawal',
    //       icon: FaLock,
    //       breadcrumbs: false
    //     }  
    //   ]
    // },
    {
      id: 'request',
      title: 'Request',
      type: 'collapse',
      icon: FaCodePullRequest,
      children: [
        {
          id: 'Point',
          title: 'Points',
          type: 'item',
          url: '/request/points',
          breadcrumbs: false
        },
        {
          id: 'Wallet',
          title: 'Wallet',
          type: 'item',
          url: '/request/withdrawal',
          breadcrumbs: false
        },
        
      ]
    },
  ]
};

export default utilities;
