import React from 'react';
import { Modal } from 'react-bootstrap';
import checkedImage from '../../assets/images/checked 1.png';
import './ReqPointConf.css'

const RedeemSuccessModal = ({setsuccessModal,successModal}) => {
  return (
    <Modal className='point_conf_modal' show={successModal} onHide={()=>{setsuccessModal(false)}}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div>
            <img src={checkedImage} alt='check_img' height={100} width={100}/>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default RedeemSuccessModal