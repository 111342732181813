import requests from "./httpService";
import { useNavigate } from "react-router";

const AdminServices = {
//   registerAdmin: async (body) => {
//     return requests.post("/admin/register", body);
//   },

  loginAdmin: async (body) => {      
    return requests.post(`admin/v1/login`, body);
  },
  fetchPrfoile:async()=>{
     return requests.get('admin/v1/fetch-profile');
  } ,  

  forgetPassword: async (body) => {
    return requests.post("/admin/forget-password", body);
  },

  resetPassword: async (body) => {
    return requests.post("/admin/reset-password", body);
  },

  signUpWithProvider: async (body) => {
    return requests.post("/admin/signup", body);
  },

  addStaff: async (body) => {
    return requests.post("/admin/add", body);
  },
  getAllStaff: async (body) => {
    return requests.get("/admin", body);
  },
  getStaffById: async () => {
    return requests.get(`/admin/fetch-profile`);
  },

  updateStaff: async (id, body) => {
    return requests.put(`/admin/${id}`, body);
  },

  updateStaffStatus: async (id, body) => {
    return requests.put(`/admin/update-status/${id}`, body);
  },

  deleteStaff: async (id) => {
    return requests.delete(`/admin/${id}`);
  },
  updateProfile:async(body)=>{
     return requests.post('/admin/update-profile',body)
  }
};

export default AdminServices;
