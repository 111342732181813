import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './ReqPointConf.css';
import RedeemSuccessModal from './RedeemSuccessModal';
import checkedImage from '../../assets/images/checked 1.png';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const RequestPointsConfirmModal = ({refreshData,confirmModal,setconfirmModal,setexplosion,confirmModalData,setrefreshData}) => {
    const [successModal,setsuccessModal]=useState(false);
    const [loader,setloader]=useState(false);
    const [desc,setdesc]=useState('');
    console.log("confirmModalData",confirmModalData);
    const handleExplosion=async()=>{
        // const formdata=new FormData();
        // formdata.append('withdrawalId',confirmModalData?._id);
        // formdata.append('status','accepted');
        setloader(true);
        const token=localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/v1/reward/operation`,{
            withdrawalId:confirmModalData?._id,
            status:'accepted',
            ...(desc && { description: desc })
        },{
            headers:{
                "Authorization":`Bearer ${token}`
            }
        })
        .then((res)=>{
        setloader(false);
        console.log("res",res);
        setexplosion(true);
        setconfirmModal(false);
        setsuccessModal(true);
        setrefreshData(!refreshData);
    })
        .catch((err)=>{console.log("err",err);setloader(false);})
        
    }
  return (
    <>
    
    {!successModal?<Modal className='point_conf_modal'  show={confirmModal} onHide={()=>{setconfirmModal(false)}}>
    {loader &&<div className="loader-overlay">
        <CircularProgress size={50} thickness={4} style={{marginRight:'100px'}} />
    </div>}
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Typography className='points_redeem_text'>Enter points to be redeem</Typography>
        <div class="container">
            <input value={confirmModalData?.amount} type="text" id="points" name="points" disabled/>
            <span className='pts'>pts</span>
        </div>
        <Typography className='points_redeem_text'>Request Id</Typography>
        <div class="container">
            <input value={"#" + confirmModalData?.uniqueId} type="text" id="points" name="points" disabled/>
        </div>
        <Typography className='points_redeem_text'>Description (Optional)</Typography>
        <div class="textarea-container">
        <textarea onChange={(e)=>{setdesc(e.target.value)}} id="points" name="points" rows="5" cols="20"></textarea>
        </div>
        <button onClick={()=>{handleExplosion()}} className='redeem_points_button'>Redeem Points</button>
      </Modal.Body>
      <RedeemSuccessModal setsuccessModal={setsuccessModal} successModal={successModal}/>
    </Modal>:
    <Modal className='point_conf_modal' show={successModal} onHide={()=>{setsuccessModal(false)}}>
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
        <div className='img_text' style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
        <img src={checkedImage} alt='check_img' height={150} width={150}/>
        <Typography className='points_reward_text'>Points reward has been sent successfully</Typography>
        </div>
    </Modal.Body>
    </Modal>
    }
    </>
  )
}

export default RequestPointsConfirmModal