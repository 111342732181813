import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 50000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

const requests = {
  get: (url, headers) => instance.get(url, { headers },{mode:'cors'}).then(responseBody),

  post: (url, body, headers) =>
    instance.post(url, body, { headers }).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, { headers }).then(responseBody),

  patch: (url, body, headers) =>
    instance.patch(url, body, { headers }).then(responseBody),

  delete: (url, headers) =>
    instance.delete(url, { headers }).then(responseBody),
};

export default requests;
